<template>
	<!-- 记事信息 -->
  <div class="mod-config">
	<el-card body-style="padding-bottom: 0;">
		<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
			<el-row>
				<el-col :span="16">
					<el-form-item>
						<el-tag type="danger">施工进度详细</el-tag>
					</el-form-item>
					<el-form-item label="记事时间">
						<el-date-picker
						  class="selItemInput"
						  v-model="recContentTime"
						  value-format="yyyy-MM-dd"
						  type="daterange"
						  range-separator="至"
						  start-placeholder="开始日期"
						  end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="8" class="selBtnGroup">
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain size="mini" icon="el-icon-back" @click="returnMenu">返回</el-button>
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain size="mini" icon="el-icon-refresh" @click="resetFormData()">重置</el-button>
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain size="mini" icon="el-icon-search" @click="selGetDataList()" v-if="showSearch">检索</el-button>
				</el-col>
			</el-row>
		</el-form>
	</el-card>
	<el-row style="margin-top: 20px;">
		<el-col :span="pageSpan" v-if="showAdd">
			<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-circle-plus-outline" @click="addOrUpdateHandle()">添加</el-button>
		</el-col>
		<el-col :span="pageSpan" style="text-align: right;">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :pager-count="5"
			  @current-change="currentChangeHandle"
			  :current-page="page"
			  :page-size="limit"
			  :total="totalCount">
			</el-pagination>
		</el-col>
	</el-row>
	<el-row :gutter="20">
		<el-col :span="8" style="margin-top: 20px;" v-for="(item,index) in dataList">
			<el-card>
				<el-row>
					<el-col :span="12">
						<span style="color: #00428e; margin-right: 10px; font-weight: bold;">记事时间</span>
						<span style="color: #727580;">{{item.registerTime}}</span>
					</el-col>
					<el-col :span="12">
						<span style="color: #00428e; margin-right: 10px; font-weight: bold;">记录人</span>
						<span style="color: #727580;">{{item.registerMan}}</span>
					</el-col>
					<el-col :span="24">
						<div class="noteTitle">内容</div>
						<div class="noteContent" style="height: 60px; line-height: 30px; overflow: hidden;">
							{{item.progressContent}}
						</div>
						<div style="text-align: right; margin-top: 20px;" v-if="showDelete || showEdit">
							<el-button v-preventReClick class="primaryPlainBtn" type="primary" size="mini" plain icon="el-icon-edit-outline" @click="addOrUpdateHandle(item.recNo)" v-if="showEdit">修改</el-button>
							<el-button v-preventReClick class="primaryPlainBtn" type="primary" size="mini" plain icon="el-icon-delete" @click="deleteHandle(item.recNo)" v-if="showDelete">删除</el-button>
						</div>
					</el-col>
				</el-row>
			</el-card>
		</el-col>
	</el-row>
	<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList()"></add-or-update>
  </div>
</template>
<script>
	import AddOrUpdate from './progressList-add-or-update'
	import $common from "@/utils/common.js"
	export default {
	  data() {
		return {
			showSearch: true,
			showAdd: true,
			showEdit: true,
			showDelete: true,
			pageSpan: 12,
			form:{},
			// subjectList:[],
			dataList:[],
			recContentTime:[],
			// subjectNo:"",
			limit: 6,
			page: 1,
			totalCount:0,
		    addOrUpdateVisible: false,
		};
	  },
	  components: {
		  AddOrUpdate
	  },
	  mounted() {
		// this.getSubjectDropDown();
		//   this.showSearch = $common.isAuth('progressList:search');
		//   this.showAdd = $common.isAuth('progressList:add');
		//   this.showEdit = $common.isAuth('progressList:edit');
		//   this.showDelete = $common.isAuth('progressList:delete');
		  if (!this.showAdd){
			  this.pageSpan = 24;
		  }
	  	this.getDataList();
	  },
	  methods: {
		  returnMenu(){
			  this.$router.go(-1)
		  },
		selGetDataList(){
			this.page = 1;
			this.getDataList();
		},
		resetFormData(){
			this.subjectNo = "";
			this.recContentTime = [];
		},
		currentChangeHandle(val) {
			this.page = val
			this.getDataList()
		},
		getDataList(){
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectdetailedprogress/list",
					method: "get",
					params: {
						limit: this.limit,
						page: this.page,
						beginTime: this.recContentTime[0] ? this.recContentTime[0] : '',
						endTime: this.recContentTime[1] ? this.recContentTime[1] : '',
						subjectNo: $common.getItem("subjectNo"),
						workNo: $common.getItem("workNo")
					},
				}).then(({data}) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalCount = data.body.totalCount;
					}
				});
			}
		},
		addOrUpdateHandle(id){
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
			  this.$refs.addOrUpdate.init(id)
			})
		},
		deleteHandle (id) {
			this.$confirm(`您确定要删除选中数据吗?`, '提示', {
		        confirmButtonText: '确定',
		        cancelButtonText: '取消',
		        type: 'warning'
		    }).then(() => {
		        this.$http({
					url:  this.$store.state.httpUrl + "/business/projectdetailedprogress/delete/"+id,
		            method: 'post'
		        }).then(({data}) => {
		            if (data && data.resultCode === 200) {
		                this.$message({
		                    message: '操作成功',
		                    type: 'success',
		                    duration: 1500,
		                    onClose: () => {
		                        this.getDataList()
		                    }
		                })
		            } else {
		                this.$message.error(data.msg)
		            }
		        })
		    })
		},
	  }
	};
</script>
<style lang="scss" scoped="scoped">
	.noteTitle{
		font-weight: bold;
		color: #00428e;
		border-bottom: 1px solid #00428e;
		padding-bottom: 10px;
		margin-top: 20px;
	}
	.noteContent{
		color: #727580;
		margin-top: 10px;
		line-height: 25px;
	}
</style>
scope
